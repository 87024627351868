import { Link } from 'gatsby';
import React, {
  useEffect,
  useState,
} from 'react';
import { SizeMe } from 'react-sizeme';
import StackGrid from 'react-stack-grid';
import blogListStyles from './bloglist.module.scss';

import cx from 'classnames';
import Img from 'gatsby-image';
import Category from '../Category/Category';
import PageFooter from '../pageFooter/pageFooter';

function BuildImage(image: any) {
  const imageEl = image.hero_image;
  return !!imageEl ? (
    <Img
      className={cx(
        blogListStyles.imageContainer
      )}
      fluid={
        imageEl.childImageSharp.fluid
      }
      alt={image.title}
    />
  ) : null;
}

const MdBlogList = ({list}: any) => {
  const [blogData, setBlogData] =
    useState(list);
  console.log(blogData);

  useEffect(() => {
    setTimeout(() => {
      setBlogData([...blogData]);
    }, 400);
    return () => {};
  }, []);

  const renderSymbol = (
    type: string
  ) => {
    return type === 'blog'
      ? '☱'
      : type === 'project'
      ? '☆'
      : type === 'graphic'
      ? '⛋'
      : '·';
  };

  function renderBlogData() {
    return (
      blogData.length > 0 && (
        <>
          <SizeMe>
            {({size}) => {
              return (
                <StackGrid
                  appearDelay={100}
                  columnWidth={ '100%'
                    // size.width >= 960
                    //   ? '33%'
                    //   : size.width >=
                    //     640
                    //   ? '50%'
                    //   : '100%'
                  }
                  gutterWidth={
                    size.width <= 640
                      ? 15
                      : 20
                  }
                  gutterHeight={
                    size.width <= 640
                      ? 15
                      : 20
                  }>
                  {blogData
                    .filter(
                      (blog) =>
                        blog.node
                          .frontmatter
                          .title !== ''
                    )
                    .map((blog) => {
                      return (
                        <div
                          className={cx(
                            blogListStyles.post,
                            'font-charisma'
                          )}
                          key={
                            blog.node.id
                          }>

                          <Link
                            className="cursor-pointer"
                            to={`/${blog.node.fields.type}/${blog.node.fields.slug}`}>
                            <li
                              className={cx(
                                blogListStyles.element
                              )}
                              key={
                                blog
                                  .node
                                  .fields
                                  .slug
                              }>
                              {/* IMAGE */}
                              {blog.node
                                ?.fields
                                .type !==
                                'blog' && (
                                <div
                                  className={
                                    blogListStyles.list__hero
                                  }>
                                  {BuildImage(
                                    blog
                                      .node
                                      .frontmatter
                                  )}
                                </div>
                              )}
                              <div className="px-4 py-4 relative">
                                {/* TITLE */}
                                <img className={cx('h-3 w-3 hidden md:block', blogListStyles.icon)} src="/images/arrow-right.svg"/>

                                <h2 className="text-base md:text-xl md:text-gray-800 text-gray-700 leading-snug">
                                  {
                                    blog
                                      .node
                                      .frontmatter
                                      .title
                                  }
                                </h2>

                                {/* CATEGORIES */}
                                <div className="hidden md:flex my-3">
                                  {blog.node.frontmatter.categories?.map(
                                    (
                                      cat: string
                                    ) => {
                                      return (
                                        <>
                                          <Category
                                            tooltip
                                            key={
                                              cat
                                            }
                                            // text
                                            category={
                                              cat
                                            }
                                            isClickable={
                                              false
                                            }
                                            size={
                                              10
                                            }
                                          />
                                          {/* <Category isClickable={false} key={cat} size={8} category={cat}/> */}
                                        </>
                                      );
                                    }
                                  )}
                                </div>

                                {/* EXCERPT */}
                                <p className="py-3 text-gray-500 tracking-wide">
                                  {
                                    blog
                                      .node
                                      .excerpt
                                  }
                                </p>

                                {/* DATE */}
                                <div
                                  className={cx(
                                    'opacity-75 text-gray-500 tracking-wide md:block md:item-center mr-1 mt-1'
                                  )}>
                                  <p className="text-xs">
                                    {
                                      blog
                                        .node
                                        .frontmatter
                                        .date
                                    }
                                    <span
                                      className={
                                        'ml-2 text-xs h-full text-gray-600'
                                      }>
                                      {renderSymbol(
                                        blog
                                          .node
                                          ?.fields
                                          .type
                                      )}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </li>
                          </Link>
                        </div>
                      );
                    })}
                </StackGrid>
              );
            }}
          </SizeMe>
          <PageFooter />
        </>
      )
    );
  }

  return (
    <section>
      <div
        className={cx(
          blogListStyles.mainContainer
        )}>
        {
          // setTimeout(el => {
          renderBlogData()
          // }, 100)
        }
      </div>
    </section>
  );
};

export default MdBlogList;
