import cx from 'classnames';
import React, { useEffect, useState } from 'react'; // we need this to make JSX compile
import MdBlogList from '../../components/BlogList/MdBlogList';
import CatList from '../../components/CategoryList/CatList';
import SEO from '../../components/seo';
import { usePageContext } from '../../contexts/pageContext';
import { useMdData } from '../../static-queries/useBlogData';
import styles from './blog.module.scss';

type IProjectsProps = {};

const Projects: React.FC<IProjectsProps> = ({}) => {
  const data = useMdData();
  const blogData = data.posts.edges;
  const [headerOpacity, setHeaderOpacity] = useState(1);

  const handleScroll = () => {
    const distanceFromTop = window.scrollY;
    const newOpacity = Math.max(1 - distanceFromTop / 60, 0);
    setHeaderOpacity(newOpacity);
  };
  const {setIsSideBarShown, isSideBarShown} = usePageContext();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <React.Fragment>
      <div className={styles.background}></div>
      <article
        className={cx(
          'w-full px-6 pb-6 min-h-screen flex items-center flex-col',
          styles.mainContainer
        )}>
        <SEO title={'Blog | Gordo Labs'} />
        <div
          className={cx('hidden md:block w-full', !isSideBarShown && 'pl-8')}>
          <CatList />
        </div>
        <section
          className={cx(
            styles.introSection,
            'flex items-center justify-center flex-col relative text-center overflow-hidden md:py-10 py-8 w-full mb-1'
          )}>
          <h2
            className="font-gordo text-xl md:text-3xl tracking-wider rounded-2xl px-4 py-2  items-center flex gap-3 md:mt-0 mt-20"
            style={{opacity: headerOpacity}}>
            <span>☱</span>
          </h2>
        </section>

        <section className={styles.base}>
          <MdBlogList list={blogData} />
        </section>
      </article>
    </React.Fragment>
  );
};

Projects.defaultProps = {};

export default Projects;
